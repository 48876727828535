import Amplitude from 'amplitude-js'
import { getFullUserAttributes } from '../Features/utils'

export const amplitude = Amplitude.getInstance()

type AmplitudeProperties = { [property: string]: string | number | boolean }

export default class AmplitudeService {
  private readonly sdkKey: string

  constructor({ sdkKey }: { sdkKey: string }) {
    this.sdkKey = sdkKey
  }

  init({ userId }: { userId: string }) {
    const options = {
      includeReferrer: true,
      includeUtm: true,
      saveParamsReferrerOncePerSession: false,
      includeGclid: true,
    }

    amplitude.init(this.sdkKey, userId, options)
    this.setUserProperties(getFullUserAttributes())
  }

  setUserProperties(userProperties: AmplitudeProperties) {
    amplitude.setUserProperties(userProperties)
  }

  identifyUser(userId: string) {
    amplitude.setUserId(userId)
  }

  setEmail(email: string) {
    this.setUserProperties({ Email: email })
  }

  setUserName(name: string) {
    this.setUserProperties({ Name: name })
  }

  track(eventName: string, eventProperties: AmplitudeProperties) {
    if ((amplitude as any)._isInitialized) {
      amplitude.logEvent(eventName, eventProperties)
    } else {
      setTimeout(() => {
        // first screen loaded (give it some time to load the amplitude package)
        amplitude.logEvent(eventName, eventProperties)
      }, 1000)
    }
  }

  getSessionId(): number {
    return amplitude.getSessionId()
  }

  setSessionId(sessionId: number) {
    return amplitude.setSessionId(sessionId)
  }
}
