var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"e631dd49a71665de39a73c096be4cc567c298e86"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import Config from '@config/config'
import AnalyticsManager from 'services/analytics/analyticsManager'
import { ANALYTICS_SERVICES_NAMES } from 'services/analytics/analyticsConfig'
import SentryService from './services/analytics/SentryService'
import AmplitudeService from './services/analytics/AmplitudeService'
import EVENT_NAMES from './consts/eventNames'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  async beforeSend(event, hint) {
    const error = hint.originalException
    const shouldReport = await (
      AnalyticsManager.getService(
        ANALYTICS_SERVICES_NAMES.SENTRY
      ) as SentryService
    ).onError(error)

    if (shouldReport) {
      if (event.event_id) {
        const amplitudeService = AnalyticsManager.getService(
          ANALYTICS_SERVICES_NAMES.AMPLITUDE
        ) as AmplitudeService
        amplitudeService.track(EVENT_NAMES.SENTRY_ERROR_REPORTED, {
          'Event Id': event.event_id,
          Message: event.exception?.values?.[0]?.value,
        })
      }
      return event
    }
  },
  dsn:
    SENTRY_DSN ||
    'https://e88c5e7239f14d98b6601ab21d4cfab8@o223238.ingest.sentry.io/4504746297458688',
  integrations: [
    Sentry.captureConsoleIntegration({
      levels: ['error'],
    }),
  ],
  attachStacktrace: true,
  tracesSampleRate: 0.09,
  enabled: Config.isProduction,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
